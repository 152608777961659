<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        During two weeks, I had to collect and translate the clients need, design a website that fit the financial
        field, but also reminding of the Islamic finance with integration of arabic patterns and photography. I chose to
        develop this website using wordpress as the site had to be easy to maintain, quickly developed and deployed, to
        fit in Euris’ roadmap launching their different services this year.
      </p>
      <br />
    </div>
    <div class="full-section half-section-media">
      <img src="@/assets/projects/euris/album/1.png" alt="" />
    </div>
    <div class="half-section half-section-media ">
      <img src="@/assets/projects/euris/album/2.png" alt="" />
    </div>

    <div class="half-section half-section-media">
      <img src="@/assets/projects/euris/album/3.png" alt="" />
    </div>
    <div class="half-section">
      <p>
        This was a very exciting project as I had to setup a proper development environment before pushing into
        production, set up a testing environment for the client to be able to see my progress and give me their
        feedback, and implement best practices to increase the SEO score of the page.
      </p>
    </div>

    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        I learnt a lot about the Islamic banking industry. That is the part I’m the most passionate about when working
        as a free-lance : I get to learn about new fields or occupations thanks to my clients projects, and gain a lot
        of side skills and specialized knowledge about what they do.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Euris",
};
</script>
<style scoped>

</style>
